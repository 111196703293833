class SurveyNameBuilder {

  run = () => {
    this.setInitialValues();
    this.newSurveyFormListener();
    this.newTMX360FormListener();
  };

  newSurveyFormListener = () => {
    $('#survey-topic, #survey-time-frame, #survey_leader_name, #survey_leader_name_360_tmx, #survey-time-frame-360-tmx, #survey-topic-360-tmx').on('change keyup input', function (e) {
      const topicTextValue = $('#survey-topic').val();
      let topicText = topicTextValue === '' ? '(Topics)' : topicTextValue;
      const timeFrameTextValue = $('#survey-time-frame').val();
      let timeFrameText = timeFrameTextValue === '' ? '(Time Period)' : timeFrameTextValue;
      const surveyNameValue = $('#survey_leader_name').val();
      let surveyName = surveyNameValue === '' ? '(Subject)' : surveyNameValue;
      let actionWordString = $('#survey-topic').val() === 'values' ? 'maintain' :
        ($('#survey-topic').val() === 'inclusion' || $('#survey-topic').val() === 'wellbeing') ? 'ensure' : 'achieve'

      $('.action').text(actionWordString);
      $('.topic_text').text(topicText);
      $('.time_period_text').text(timeFrameText);
      $('.name_placeholder').text(surveyName);
    })
  };

  newTMX360FormListener = () => {
    $('#survey_leader_name_360_tmx, #survey-time-frame-360-tmx, #survey-topic-360-tmx, .behavior').on('change keyup input', function (e) {
      const topicTextValue = $('#survey-topic-360-tmx').val();
      let topicText = topicTextValue === '' ? '(Topic)' : topicTextValue;
      const timeFrameTextValue = $('#survey-time-frame-360-tmx').val();
      let timeFrameText = timeFrameTextValue === '' ? '(Time Period)' : timeFrameTextValue;
      const surveyNameValue = $('#survey_leader_name_360_tmx').val();
      let surveyName = surveyNameValue === '' ? '(Subject)' : surveyNameValue;
      const behaviorTextValue = $('.behavior').val();
      let behaviorText = behaviorTextValue === '' ? '(Behavior)' : behaviorTextValue;

      $('.topic_text').text(topicText);
      $('.time_period_text').text(timeFrameText);
      $('.name_placeholder').text(surveyName);
      $('.behavior').text(behaviorText);
    })
  };

  setInitialValues = () => {
    $('.action').text('achieve');
    $('#survey-topic').val('').trigger('change');
    $('.time_period_text').text('(Time Period)');
    $('.topic_text').text('(Topics)');
    $('.name_placeholder').text('(Subject)');
    $('.behavior').text('(Behavior)');
  };
}

export default SurveyNameBuilder;