class Presets {

  presetsCases (leader_name) {
    $('.presets').off('click').on('click', (e) => {
      let option = e.target.id

      if (option === 'team_card') {
        this.fillFormFields('your teammates','team objectives', 'achieve', 'month');
      } else if (option === 'leader_card') {
        this.fillFormFields(leader_name, 'team objectives', 'achieve', 'month');
      } else if (option === 'value_card') {
        this.fillFormFields('your teammates','sense of inclusion', 'ensure', 'month');
      } else if (option === 'other_card') {
        this.fillFormFields('','team objectives', 'achieve', 'month');
      } else if (option === 'tmx360_card') {
        this.fill360TmxForm(leader_name, 'your ability to accomplish your objectives', 'quarter', 'behavior');
      } else {
        this.fillFormFields('', '', 'achieve');
      }
    });
  };

  otherTeamInputListener = () => {
    $('.another-name-field').on("change keyup input", (e) => {
      $("#survey_leader_name").val(e.target.value).trigger('change');
    });
  };

  fill360TmxForm = (survey_name, topic, period, behavior) => {
    let $newOption = $("<option selected='selected'></option>").val(topic).text(topic)

    $("#survey_leader_name_360_tmx").val(survey_name).trigger('change');
    $('#survey-topic-360-tmx').append($newOption).trigger('change');
    $('#survey-time-frame-360-tmx').val(period).trigger('change');
    $('.behavior').val(behavior);
  };

  fillFormFields = (survey_name, topic, action, period) => {
    let $newOption = $("<option selected='selected'></option>").val(topic).text(topic)

    $("#survey_leader_name").val(survey_name).trigger('change');
    $('#survey-topic').append($newOption).trigger('change');
    $('.action').text(action);
    $('#survey-time-frame').val(period).trigger('change');
  };

  setPresetToRecurringContainer = (timePeriod, value, title, defaultText, text ) => {
    let $time_period = $('#recurring_period');
    let $recurring_value = $('#recurring_value');
    let $recurrence_container_title = $('#recurrence-container-title');
    let $recurrence_default = $('#recurrence-default');
    let $recurrence_text = $('#recurrence-text');

    $time_period.val(timePeriod).trigger('change');
    $recurring_value.val(value);
    $recurrence_container_title.text(title);
    $recurrence_default.text(defaultText);
    $recurrence_text.text(text);
  };

  presetsFinalize = () => {
    let recurrency = sessionStorage.getItem('recurrence');
    let option = sessionStorage.getItem('option');

    if (recurrency === 'true') {
      if (option === "team_card" || option === 'other_card' || option === 'value_card') {
        this.setPresetToRecurringContainer(
          'monthly',
          11,
          'Monthly',
          '(Default for in team & cross-team feedback)',
          'Get feedback every other week. These surveys will be sent the same day each month for the next year.'
        );
      } else if (option === 'leader_card'|| option === 'custom_card') {
        this.setPresetToRecurringContainer(
            'monthly',
            11,
            'Monthly',
            '(Default for leader & custom feedback)',
            'Get feedback once a month. These surveys will be sent the same day each month for the next year.'
        );
      } else if ( option === 'tmx360_card' ) {
          this.setPresetToRecurringContainer(
              'quarterly',
              3,
              'Quarterly',
              '(Default for TMX360 feedback)',
              'Get feedback each quarter. These surveys will be sent each quarter for the next year.'
          );
      } else {
        this.setPresetToRecurringContainer(
            'monthly',
            11,
            'Monthly',
            '',
            'Get feedback once a month. These surveys will be sent the same day each month for the next year.'
        );
      }
    }
  };
}

export default Presets;